import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectHelper = (state) => state.helper || initialState;

const currentCreateAccountStepSelector = () =>
  createSelector(selectHelper, (state) => state.currentCreateAccountStep);

const isFeelingsDialogOpenSelector = () =>
  createSelector(selectHelper, (state) => state.isFeelingsDialogOpen);

const createAccountQuestionSelector = () =>
  createSelector(selectHelper, (state) => state.createAccountQuestion);

const isFinalFeelingsDialogOpenSelector = () =>
  createSelector(selectHelper, (state) => state.isFinalFeelingsDialogOpen);

const isDownloadJourneyDialogOpenSelector = () =>
  createSelector(selectHelper, (state) => state.isDownloadJourneyDialogOpen);

export {
  currentCreateAccountStepSelector,
  isFeelingsDialogOpenSelector,
  isDownloadJourneyDialogOpenSelector,
  isFinalFeelingsDialogOpenSelector,
  createAccountQuestionSelector,
};
