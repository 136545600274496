import {
  GET_CURRENT_CREATE_ACCOUNT_STEP_SAGA,
  SET_CREATE_ACCOUNT_QUESTION,
  SET_CURRENT_CREATE_ACCOUNT_STEP,
  SET_IS_DOWNLOAD_JOURNEY_DIALOG_OPEN,
  SET_IS_FEELINGS_DIALOG_OPEN,
  SET_IS_FINAL_FEELINGS_DIALOG_OPEN,
} from './actionTypes';

export function setCurrentCreateAccountStep(payload) {
  return {
    type: SET_CURRENT_CREATE_ACCOUNT_STEP,
    payload,
  };
}

export function setCreateAccountQuestion(payload) {
  return {
    type: SET_CREATE_ACCOUNT_QUESTION,
    payload,
  };
}

export function getCurrentCreateAccountStepSaga() {
  return {
    type: GET_CURRENT_CREATE_ACCOUNT_STEP_SAGA,
  };
}

export function setIsFeelingsDialogOpen(payload) {
  return {
    type: SET_IS_FEELINGS_DIALOG_OPEN,
    payload,
  };
}

export function setIsFinalFeelingsDialogOpen(payload) {
  return {
    type: SET_IS_FINAL_FEELINGS_DIALOG_OPEN,
    payload,
  };
}

export function setIsDownloadJourneyDialogOpen(payload) {
  return {
    type: SET_IS_DOWNLOAD_JOURNEY_DIALOG_OPEN,
    payload,
  };
}
