import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  fetchAuthenticatedUserSuccess,
  logoutSuccess,
  loginSuccess,
  loginError,
  setToken,
  setLoginError,
  setActiveStep,
  setQuestionType,
} from './actions';
import { startAction, stopAction } from '../loading/actions';
import {
  LOGIN_REQUEST,
  FETCH_AUTHENTICATED_USER_REQUEST,
  LOGOUT_REQUEST,
  UPDATE_SAGA,
  SET_CUSTOM_QUESTION_SAGA,
  ANSWER_QUESTION_SAGA,
} from './actionTypes';
import { CREATE_ACCOUNT_PAGE, LOGIN } from 'routes';
import authService from 'services/AuthService';
import { HTTP_STATUS_CODES } from 'consts';
import { removeItem } from '../../utils/localStorage';
import profileService from '../../services/ProfileService';
import { setCreateAccountQuestion } from '../helper/actions';
import { createAccountQuestionSelector } from '../helper/selectors';
import { activeStepSelector } from './selectors';
export function* authorize({ type, username, password }) {
  try {
    yield put(startAction(type));
    const res = yield call(authService.login, { username, password });
    if (res) {
      const user = yield call(authService.fetchAuthenticatedUser);
      yield put(loginSuccess());
      yield put(setToken('token', res));
      if (user?.terms_and_policy) {
        yield put(push('/'));
      } else {
        const customQuestions = yield call(
          profileService.getCreateAccountQuestion
        );
        yield put(setCreateAccountQuestion(customQuestions));
        yield put(push(CREATE_ACCOUNT_PAGE));
      }
    }
  } catch (error) {
    if (error && error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      yield put(setLoginError(error.data.message));
    }
    yield put(loginError());
  } finally {
    yield put(stopAction(type));
  }
}

export function* fetchUser({ type }) {
  yield put(startAction(type));
  try {
    const user = yield call(authService.fetchAuthenticatedUser);
    yield put(fetchAuthenticatedUserSuccess(user));
  } catch (error) {
    console.log({ error });
  } finally {
    yield put(stopAction(type));
  }
}

export function* logout() {
  try {
    yield put(logoutSuccess());
    yield call(removeItem, 'token');
    yield put(push(LOGIN));
  } catch (error) {
    console.log(error);
  }
}

export function* updateSaga({ payload }) {
  try {
    const res = yield call(authService.update, payload);
    yield put(fetchAuthenticatedUserSuccess(res));
    yield put(setActiveStep(null));
    yield put(setQuestionType(null));
    yield put(push('/'));
  } catch (error) {
    console.log({ error });
  }
}

export function* setCustomQuestionSagaFn() {
  try {
    const customQuestions = yield select(createAccountQuestionSelector());
    let activeStep = 0;
    let onboardingIncomplete = false;
    let questionType = '';
    customQuestions.onboardingQuestions.some((item, index) => {
      if (item.customQuestionAnswers.length === 0) {
        activeStep = index;
        onboardingIncomplete = true;
        questionType = 'onboardingQuestions';
        return true;
      }
      return false;
    });
    if (!onboardingIncomplete) {
      const user = yield call(authService.fetchAuthenticatedUser);
      if (user?.terms_and_policy) {
        if (customQuestions.closingQuestions.length === 0) {
          activeStep = null;
        } else {
          customQuestions.closingQuestions.some((item, index) => {
            if (item.customQuestionAnswers.length === 0) {
              activeStep = 0;
              questionType = 'closingQuestions';
              return true;
            }
            questionType = 'closingQuestions';
            return false;
          });
        }
      } else {
        questionType = 'terms';
      }
    }
    yield put(setActiveStep(activeStep));
    yield put(setQuestionType(questionType));
  } catch (error) {
    console.log({ error });
  }
}

export function* answerQuestionSagaFn({ payload }) {
  try {
    const selectedStep = yield select(activeStepSelector());
    yield call(profileService.answerCustomQuestion, payload);
    const customQuestions = yield call(profileService.getCreateAccountQuestion);
    yield put(setCreateAccountQuestion(customQuestions));
    let activeStep = 0;
    let onboardingIncomplete = false;
    let questionType = '';
    customQuestions.onboardingQuestions.some((item, index) => {
      if (item.customQuestionAnswers.length === 0) {
        onboardingIncomplete = true;
        questionType = 'onboardingQuestions';
        return true;
      }
      activeStep = selectedStep + 1;
      return false;
    });
    if (!onboardingIncomplete) {
      const user = yield call(authService.fetchAuthenticatedUser);
      if (user?.terms_and_policy) {
        const allNonEmpty = customQuestions.closingQuestions.every(
          (item) =>
            item.customQuestionAnswers && item.customQuestionAnswers.length > 0
        );
        if (allNonEmpty) {
          if (selectedStep < customQuestions.closingQuestions.length - 1) {
            activeStep = selectedStep + 1;
            questionType = 'closingQuestions';
          } else {
            questionType = 'closeDone';
          }
        } else {
          customQuestions.closingQuestions.some((item, index) => {
            if (item.customQuestionAnswers.length === 0) {
              activeStep = index;
              questionType = 'closingQuestions';
              return true;
            }
            return false;
          });
        }
      } else {
        questionType = 'terms';
      }
    }
    yield put(setQuestionType(questionType));
    yield put(setActiveStep(activeStep));
  } catch (error) {
    console.log({ error });
  }
}

export default function* authSaga() {
  yield takeLatest(LOGIN_REQUEST, authorize);
  yield takeLatest(ANSWER_QUESTION_SAGA, answerQuestionSagaFn);
  yield takeLatest(FETCH_AUTHENTICATED_USER_REQUEST, fetchUser);
  yield takeLatest(LOGOUT_REQUEST, logout);
  yield takeLatest(UPDATE_SAGA, updateSaga);
  yield takeLatest(SET_CUSTOM_QUESTION_SAGA, setCustomQuestionSagaFn);
}
