import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  UPDATE_PROFILE: '/user',
  UPDATE_ME: '/respondents/update-me',
  SELECT_LANGUAGE: '/respondents/add-selected-language',
  CHANGE_PASSWORD: '/user/change-password',
  GET_CREATE_ACCOUNT_QUESTION: '/projects/custom-questions',
  ANSWER_CUSTOM_QUESTION: '/projects/save-custom-question-answer',
};

class AuthService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  updateProfile = (data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_PROFILE,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  selectUserLanguage = (data) => {
    return this.httpService.request({
      url: ROUTES.SELECT_LANGUAGE,
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  getCreateAccountQuestion = () => {
    return this.httpService.request({
      url: ROUTES.GET_CREATE_ACCOUNT_QUESTION,
      method: HTTP_METHODS.GET,
    });
  };

  answerCustomQuestion = (data) => {
    return this.httpService.request({
      url: ROUTES.ANSWER_CUSTOM_QUESTION,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  updateMe = (data) => {
    return this.httpService.request({
      url: ROUTES.UPDATE_ME,
      method: HTTP_METHODS.PUT,
      data,
    });
  };

  changePassword = (data) => {
    return this.httpService.request({
      url: ROUTES.CHANGE_PASSWORD,
      method: HTTP_METHODS.POST,
      data,
    });
  };
}

const authService = new AuthService(httpService);

export default authService;
