import produce from 'immer';

import {
  SET_CREATE_ACCOUNT_QUESTION,
  SET_CURRENT_CREATE_ACCOUNT_STEP,
  SET_IS_DOWNLOAD_JOURNEY_DIALOG_OPEN,
  SET_IS_FEELINGS_DIALOG_OPEN,
  SET_IS_FINAL_FEELINGS_DIALOG_OPEN,
} from './actionTypes';

export const initialState = {
  currentCreateAccountStep: null,
  isFeelingsDialogOpen: false,
  isFinalFeelingsDialogOpen: false,
  isDownloadJourneyDialogOpen: false,
  createAccountQuestion: [],
};

/* eslint-disable default-case */

const helperReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_CURRENT_CREATE_ACCOUNT_STEP:
        draft.currentCreateAccountStep = payload;
        break;
      case SET_CREATE_ACCOUNT_QUESTION:
        draft.createAccountQuestion = payload;
        break;
      case SET_IS_FEELINGS_DIALOG_OPEN:
        draft.isFeelingsDialogOpen = payload;
        break;
      case SET_IS_FINAL_FEELINGS_DIALOG_OPEN:
        draft.isFinalFeelingsDialogOpen = payload;
        break;
      case SET_IS_DOWNLOAD_JOURNEY_DIALOG_OPEN:
        draft.isDownloadJourneyDialogOpen = payload;
        break;
    }
  });

export default helperReducer;
